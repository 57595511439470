import React from "react";
import ContactSection from "../sections/ContactSection";

export default function Contact() {
  return (
    <>
      <ContactSection />
    </>
  );
}
